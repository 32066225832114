import React, { useState } from "react";
import { FORM_COMPONENTS } from "../Constants/Constants";
import FormSelector from "./FormSelector";

const Dashboard = () => {
  const [selectedForm, setSelectedForm] = useState();

  return (
    <div className='csm-container'>
      <div className='box w-full'>
        <h1 className='my-5 text-center text-3xl font-semibold '>
          CSM-Dashboard
        </h1>
        <div style={{ textAlign: "center" }}>
          <FormSelector onChange={setSelectedForm} />
          {FORM_COMPONENTS[selectedForm] ?? null}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
