import { useState } from "react";
import { Radio } from "../../uilib";
import { InviteMultipleUsers } from "./InviteMultipleUsers";
import { InviteUser } from "./InviteUser";

const INVITE_USER_ACTION_TYPE = {
  SINGLE: 1,
  MULTIPLE: 2,
};

export const InviteUsers = () => {
  const [inviteUserActionType, setInviteUserActionType] = useState(
    INVITE_USER_ACTION_TYPE.SINGLE
  );
  return (
    <>
      <div>
        <Radio
          color='secondary'
          checked={inviteUserActionType === INVITE_USER_ACTION_TYPE.SINGLE}
          onChange={() =>
            setInviteUserActionType(INVITE_USER_ACTION_TYPE.SINGLE)
          }
        >
          Invite Single User{" "}
        </Radio>
        <Radio
          color='secondary'
          checked={inviteUserActionType === INVITE_USER_ACTION_TYPE.MULTIPLE}
          onChange={() =>
            setInviteUserActionType(INVITE_USER_ACTION_TYPE.MULTIPLE)
          }
        >
          Invite Multiple Users{" "}
        </Radio>
      </div>
      <div style={{ marginTop: "12px" }}>
        {inviteUserActionType === INVITE_USER_ACTION_TYPE.SINGLE ? (
          <InviteUser />
        ) : (
          <InviteMultipleUsers />
        )}
      </div>
    </>
  );
};
