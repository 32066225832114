import { Button, DragAndDrop, showToast } from "../../uilib";
import { useRef, useState } from "react";
import { REGISTER_MULTIPLE_USER } from "../../urls";
import { postAPI } from "../../Utils/ApiCalls";
import { ConstructionUserFlag, WelcomeEmailFlag } from "./Flag";

export const InviteMultipleUsers = () => {
  const ref = useRef();
  const [files, setFiles] = useState([]);
  const [isConstructionUser, setIsConstructionUser] = useState({
    is_construction_signup: false,
  });
  const [isSenDEmail, setIsSendEmail] = useState({ send_welcome_email: false });
  const [loading, setLoading] = useState(false);

  const submitData = () => {
    const formData = new FormData();
    formData.append("file", files[0]);
    formData.append(
      "is_construction_signup",
      isConstructionUser.is_construction_signup
    );
    formData.append("send_welcome_email", isSenDEmail.send_welcome_email);
    setLoading(true);
    postAPI(REGISTER_MULTIPLE_USER, formData)
      .then((res) => {
        if (res.data !== null) {
          showToast("Users registered successfully!", "success");
          if (ref.current) {
            ref.current.resetFiles();
          }
          setFiles([]);
          setIsConstructionUser({ is_construction_signup: false });
          setIsSendEmail({ send_welcome_email: false });
        } else {
          showToast(res.error, "error");
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <div>
      <div>
        <DragAndDrop
          ref={ref}
          multiple={false}
          acceptedFileTypes={{
            "text/csv": [".csv", ".xls", ".xlsx"],
          }}
          files={setFiles}
        />
      </div>
      <div>
        <ConstructionUserFlag
          isSet={isConstructionUser.is_construction_signup}
          onChange={setIsConstructionUser}
        />
        <WelcomeEmailFlag
          isSet={isSenDEmail.send_welcome_email}
          onChange={setIsSendEmail}
        />
      </div>
      <div style={{ marginTop: "12px" }}>
        <Button
          color='secondary'
          onClick={submitData}
          loading={loading}
          disabled={!files.length}
        >
          Invite multiple users
        </Button>
      </div>
    </div>
  );
};
