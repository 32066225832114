import React, { useState } from "react";
import {
  COMPANY_VERTICAL,
  COMPANY_VERTICAL_ENUM,
  COUNTRY_CODE,
} from "../Constants/Constants";
import { fetchApiData } from "../Utils/ApiCalls";
import { DASHBOARD_ENDPOINT } from "../urls";
import useFormStatus from "../Hooks/useFormStatus";
import IncreaseFreeTrial from "./IncreaseFreeTrial";
import { WelcomeEmailFlag } from "./InviteUsers/Flag";

const RegistrationForm = () => {
  const initialFormData = {
    email: "",
    password: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    company: "",
    country: "US",
    companyVertical: "",
  };

  const [formData, setFormData] = useState(initialFormData);
  const [isBlueprint, setIsBlueprint] = useState(true);
  const [isAerial, setIsAerial] = useState(true);
  const [isInternalUser, setIsInternalUser] = useState(false);
  const [numberOfDays, setNumberOfDays] = useState("1");

  const { successMessage, errorMessage, handleApiResult } = useFormStatus();

  const handleDataChange = (val, field) => {
    setFormData({ ...formData, [field]: val });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const {
      send_welcome_email: sendWelcomeEmail,
      email,
      password,
      firstName: first_name,
      lastName: last_name,
      phoneNumber,
      company,
      country,
      companyVertical: company_vertical,
    } = formData;

    // Construct the object to send, omitting any empty phone number
    const filteredData = {
      email: email.toLowerCase(),
      password,
      first_name,
      last_name,
      company,
      country,
      is_construction_signup: isBlueprint && !isAerial ? true : false,
      company_vertical,
      free_trial_days: numberOfDays.replace(/^0+/, ""),
      is_internal: isInternalUser,
      send_welcome_email: sendWelcomeEmail,
    };
    if (phoneNumber !== "") filteredData.phone_number = phoneNumber;

    // Make the POST request to the API with the form data
    const response = await fetchApiData({
      endpoint: DASHBOARD_ENDPOINT,
      data: filteredData,
    });
    handleApiResult(response);

    if (response.error === null) setFormData(initialFormData);
  };

  return (
    <form onSubmit={handleSubmit}>
      {(errorMessage || successMessage) && (
        <div
          className={`alert ${errorMessage ? "alert-danger" : "alert-success"}`}
          role='alert'
        >
          {errorMessage || successMessage}
        </div>
      )}

      <div className='input-group mb-3 input-group-lg'>
        <input
          type='Email'
          value={formData.email}
          onChange={(e) => handleDataChange(e.target.value, "email")}
          required
          className='form-control'
          placeholder='Email'
          aria-label='Email'
        />

        <input
          type='text'
          value={formData.password}
          onChange={(e) => handleDataChange(e.target.value, "password")}
          required
          className='form-control'
          placeholder='Password'
          aria-label='Password'
        />
      </div>
      <div className='input-group mb-3 input-group-lg'>
        <input
          type='text'
          value={formData.firstName}
          onChange={(e) => handleDataChange(e.target.value, "firstName")}
          required
          className='form-control'
          placeholder='First Name'
          aria-label='FirstName'
        />

        <input
          type='text'
          value={formData.lastName}
          onChange={(e) => handleDataChange(e.target.value, "lastName")}
          required
          className='form-control'
          placeholder='Last Name'
          aria-label='LastName'
        />
      </div>

      <div className='input-group mb-3 input-group-lg'>
        <input
          type='tel'
          value={formData.phoneNumber}
          onChange={(e) => handleDataChange(e.target.value, "phoneNumber")}
          className='form-control'
          placeholder='Phone Number'
          aria-label='PhoneNumber'
          required
        />

        <input
          type='text'
          value={formData.company}
          onChange={(e) => handleDataChange(e.target.value, "company")}
          required
          className='form-control'
          placeholder='Company'
          aria-label='Company'
        />
      </div>

      <div className='input-group mb-3 input-group-lg'>
        <label className='input-group-text' htmlFor='inputGroupSelect01'>
          Country
        </label>
        <select
          className='form-select'
          id='inputGroupSelect01'
          value={formData.country}
          onChange={(e) => handleDataChange(e.target.value, "country")}
          required
        >
          <option>Choose...</option>
          <option value={COUNTRY_CODE.CANADA}>Canada</option>
          <option value={COUNTRY_CODE.USA}>United States</option>
          <option value={COUNTRY_CODE.AUSTRALIA}>Australia</option>
        </select>
      </div>

      <div className='flex justify-start pl-2'>
        <div className='form-check form-check-inline'>
          <input
            className='form-check-input'
            type='checkbox'
            checked={isBlueprint}
            onChange={(e) => setIsBlueprint(e.target.checked)}
            id='blueprint'
          />
          <label className='form-check-label' htmlFor='flexCheckDefault'>
            Blueprint
          </label>
        </div>
        <div className='form-check form-check-inline'>
          <input
            className='form-check-input'
            type='checkbox'
            checked={isAerial}
            onChange={(e) => setIsAerial(e.target.checked)}
            id='aerial'
          />
          <label className='form-check-label' htmlFor='flexCheckDefault'>
            Aerial
          </label>
        </div>
        <div className='form-check form-check-inline'>
          <input
            className='form-check-input'
            type='checkbox'
            checked={isAerial & isBlueprint}
            onChange={(e) =>
              setIsAerial(e.target.checked) & setIsBlueprint(e.target.checked)
            }
            id='both'
          />
          <label className='form-check-label' htmlFor='flexCheckDefault'>
            Both
          </label>
        </div>
        <div className='form-check form-check-inline'>
          <input
            className='form-check-input'
            type='checkbox'
            value=''
            id='flexCheckCheckedDisabled'
            checked
            disabled
          />
          <label
            className='form-check-label'
            htmlFor='flexCheckCheckedDisabled'
          >
            Is Admin
          </label>
        </div>
        <div className='form-check form-check-inline'>
          <input
            className='form-check-input'
            type='checkbox'
            checked={isInternalUser}
            onChange={(e) => setIsInternalUser(e.target.checked)}
            id='internal-user'
          />
          <label className='form-check-label' htmlFor='flexCheckDefault'>
            Is Internal User
          </label>
        </div>
      </div>
      <div className={"input-group mb-3 input-group-lg mt-2 "}>
        <label className='input-group-text' htmlFor='inputGroupSelect01'>
          Company Vertical
        </label>
        <select
          className={"form-select"}
          id='inputGroupSelect01'
          value={formData.companyVertical}
          onChange={(e) => handleDataChange(e.target.value, "companyVertical")}
          required
        >
          <option value={""}>Choose...</option>
          <option value={COMPANY_VERTICAL_ENUM.FIELD_SERVICE}>
            {COMPANY_VERTICAL[COMPANY_VERTICAL_ENUM.FIELD_SERVICE]}
          </option>
          <option value={COMPANY_VERTICAL_ENUM.GC}>
            {COMPANY_VERTICAL[COMPANY_VERTICAL_ENUM.GC]}
          </option>
        </select>
      </div>

      <IncreaseFreeTrial
        numberOfDays={numberOfDays}
        setNumberOfDays={setNumberOfDays}
      />

      <WelcomeEmailFlag
        isSet={formData.send_welcome_email}
        onChange={(obj) =>
          handleDataChange(obj.send_welcome_email, "send_welcome_email")
        }
      />

      <div className='button'>
        <button
          type='submit'
          className='bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-4 rounded'
        >
          Register
        </button>
      </div>
    </form>
  );
};

export default RegistrationForm;
