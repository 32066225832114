import { Checkbox } from "../../uilib";

export const ConstructionUserFlag = ({ isSet, onChange }) => (
  <Checkbox
    color='secondary'
    checked={isSet}
    onChange={(e) => onChange({ is_construction_signup: e.target.checked })}
    label='Construction Sign Up'
  />
);

export const WelcomeEmailFlag = ({ isSet, onChange }) => (
  <Checkbox
    color='secondary'
    checked={isSet}
    onChange={(e) => onChange({ send_welcome_email: e.target.checked })}
    label='Send user a welcome email with reset link'
  />
);
